import React from "react";
import styled from "styled-components";
import { FaFacebookF, FaTwitter, FaUserCheck } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";

const Header = styled.header`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0.6rem 2rem 0;
  //   border-bottom: 1px solid #eee;
  //   border-top: 3px solid var(--second-color);
  background-color: #121142;

  p {
    padding: 0 3px;
    font-size: var(--smaller-font-size);
    color: #fff;
  }

  .usr {
    color: var(--fifth-color);
  }

  .icn {
    cursor: pointer;
    color: #fff;
    margin: 0 2px;
    font-size: var(--normal-font-size);
  }

  p :hover,
  .icn :hover {
    color: var(--title-color);
  }

  @media screen and (max-width: 580px) {
    padding: 0.5rem 0.5rem;

    p {
      padding: 0 1px;
    }

    .icn {
      margin: 0 1px;
      font-size: var(--smaller-font-size);
    }

    .usr {
      display: none;
    }

    .forUser {
      display: none;
    }
  }
`;

const MenuBars = styled(FaUserCheck)`
  cursor: pointer;
  align-self: center;
  justify-self: center;
  margin-right: 0.4rem;
`;

const Topbar = ({ isOpen, toggler }) => {
  return (
    <Header>
      <p>
        <AiOutlineMail className="icn" />
        &nbsp; info@scaninfo.com.ng | &nbsp;&nbsp;
        <BsFillTelephoneFill className="icn" />
        &nbsp;08036071791, &nbsp; 09058914722
      </p>
      <p>
        <>
          <MenuBars onClick={toggler} />
        </>
        <FaFacebookF className="icn" /> | <FaTwitter className="icn" />
      </p>
    </Header>
  );
};

export default Topbar;
