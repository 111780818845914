import React from "react";
import styled from "styled-components";
import projec from "../images/project.webp";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 80%;
  padding: 2rem;
  margin: 1rem auto;
  background-color: #f8f8f8;

  img {
    width: 100%;
    height: auto;
    object-fit: fit;
  }

  h1 {
    color: #ba0404;
    font-weight: bold;
  }

  .project {
    margin-bottom: 20px;
  }
  .project h2 {
    color: var(--dark-bg);
    margin-bottom: 5px;
  }
  .project p {
    margin: 5px 0;
  }
  .contact {
    font-weight: bold;
  }
  .column-break {
    border-top: 1px solid #ccc;
    margin: 40px 0;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const Projects = () => {
  return (
    <InfoDiv>
      <img src={projec} alt="" />
      <h1>Similar Projects With Contacts</h1>
      <p>
        Due to the nature of our services and solutions, Scan InfoTech has a
        range of customers that cut accross all works of life. These include but
        limited to the following
      </p>
      <div className="project">
        <h2>World Health Organization (WHO)</h2>
        <p>
          Nature of Project: Consultancy Services on ICT, supply and deployment
          of all IT equipment.
        </p>
        <p className="contact">
          Contact Person: Mrs Kolo Sagi Mobile: 08034529529
        </p>
      </div>

      <div className="project">
        <h2>Jos Electricity Distribution (JED)</h2>
        <p>
          Nature of Project: Installation of IP CCTV Camera & supply of ICT
          equipment.
        </p>
        <p className="contact">
          Contact Person: Mr. Nuraddeen Dalhatu Mobile: 08033611589
        </p>
      </div>

      <div className="project">
        <h2>Plateau State University Bokkos</h2>
        <p>
          Nature of Project: Setup of Internet Access, E-library and Jamb Centre
        </p>
        <p className="contact">
          Contact Person: Mr. Obaje Alfred Michael Mobile: 08037034489
        </p>
      </div>

      <div className="project">
        <h2>Programme Management and Result Delivery Office (PMRDO)</h2>
        <p>
          Nature of Project: Deployment of Internet Services and ICT support
          Services, CCTV and Intercom
        </p>
      </div>

      <div className="project">
        <h2>Operation Rainbow, Plateau State</h2>
        <p>
          Nature of Project: Supply of bullet proof vests, drone, repair of
          Armored Personnel carrier and overhauling of Situation room
        </p>
      </div>

      <div className="project">
        <h2>Hill Crest School</h2>
        <p>
          Nature of Project: Deployment of Internet Services and ICT support
          Services, CCTV and Intercom
        </p>
      </div>

      <div className="project">
        <h2>Nigeria Erosion & Watershed Management Project (PLSNEWMAP)</h2>
        <p>
          Nature of Project: Supply of GIS Softwares, Maintenance & Upgrade of
          GIS equipment.
        </p>
      </div>

      <div className="project">
        <h2>Adamawa State Government</h2>
        <p>
          Nature of Project: Mobile Application Development on State Government
          Affairs
        </p>
      </div>

      <div className="project">
        <h2>African Sisters Education Collaborative (ASEC)</h2>
        <p>
          Nature of Project: Deployment of Internet Services and ICT support
          Services
        </p>
        <p className="contact">
          Contact Person: Mr. Dapel Felix Simon Email: dapel.asec.sldi.org
          Mobile: 09034269691
        </p>
      </div>

      <div className="project">
        <h2>Industrial Tanning Fund (ITF)</h2>
        <p>Nature of Project: ICT Consultancy and Supplies.</p>
        <p className="contact">
          Contact Person: Henry Ossai Mobile: 08038051162
        </p>
      </div>

      <div className="project">
        <h2>
          Rural Agricultural Access Marketing Project (RAAMP), Plateau State
          (November 2018)
        </h2>
        <p>
          Nature of Project: Supply and deployment of Inverter batteries' power
          backup system; designed, implement and deployed Local Area Network
          (LAN) in offices; designed, implement and deployed Wireless network
          cloud around the premises; designed, implement and deployed Voice over
          Internet Protocol (VoIP) communication system in offices; and also
          provide Internet bandwidth for connectivity.
        </p>
        <p className="contact">
          Contact Person: Ms. Linda Adar Izang Email: adarlinda@yahoo.com
          Mobile: 08032246056
        </p>
      </div>

      <div className="project">
        <h2>Plateau State Primary Healthcare Board (May 2018)</h2>
        <p>
          Nature of Project: Supply of computer systems to the primary
          healthcare board offices
        </p>
        <p className="contact">Contact Person: Mr. Yahu Mobile: 0806158446</p>
      </div>

      <div className="column-break"></div>

      <div class="project">
        <h2>ShapeShapeAremo Nigeria Limited, Abuja (2017)</h2>
        <p>
          Nature of Project: Provision and maintenance of Internet Access to
          National Information Technology Development Agency (NITDA) Site at
          Abubakar Tafawa Balewa University (ATBU), Gubi Campus, Bauchi State
        </p>
        <p class="contact">Contact Person: Mr. Hori Oluwamuyiwa</p>
        <p>
          Email:{" "}
          <a href="mailto:iloriphillips23@gmail.com">
            iloriphillips23@gmail.com
          </a>
          , Mobile: 08062941150
        </p>
      </div>

      <div class="project">
        <h2>Ogbonimodu Ventures Limited, Abuja (2017)</h2>
        <p>
          Nature of Project: Provision and maintenance of Internet Access to
          National Information Technology Development Agency (NITDA) Site at
          Federal College of Education (technical), Gombe State
        </p>
        <p class="contact">Contact Person: Mr. Ilori Oluwamuyiwa</p>
        <p>
          Email:{" "}
          <a href="mailto:iloriphillips23@gmail.com">
            iloriphillips23@gmail.com
          </a>
          , Mobile: 08062941150
        </p>
      </div>

      <div class="project">
        <h2>Ednaw James Limited, Abuja (2017)</h2>
        <p>
          Nature of Project: Provision and maintenance of Internet Access to
          National Information Technology Development Agency (NITDA) Site at
          Federal University Kashere, Gombe State
        </p>
        <p class="contact">Contact Person: Mr. Hori Oluwamuyiwa</p>
        <p>
          Email:{" "}
          <a href="mailto:iloriphillips23@gmail.com">
            iloriphillips23@gmail.com
          </a>
          , Mobile: 08062941150
        </p>
      </div>

      <div class="project">
        <h2>Nigerian Law School Kano Campus, Kano State (2015)</h2>
        <p>
          Nature of Project: Redesign and deployment of LAN and WLAN across the
          school
        </p>
        <p class="contact">Contact Person: Suleiman Olalekan</p>
        <p>
          Email:{" "}
          <a href="mailto:suleimanolalekan@yahoo.com">
            suleimanolalekan@yahoo.com
          </a>
          , Mobile: 08030664241, 08182010577
        </p>
      </div>

      <div class="project">
        <h2>ABU, Zaria (2012)</h2>
        <p>
          Nature of Project: Cisco Networking Academy Trainings in partnership
          with University of Jos.
        </p>
        <p class="contact">Contact Person: Mr. Chris Anthony</p>
        <p>
          Email:{" "}
          <a href="mailto:chris.a.machu@gmail.com">chris.a.machu@gmail.com</a>,
          Mobile: +234-8034833778
        </p>
      </div>
    </InfoDiv>
  );
};

export default Projects;
