import React from "react";
import PageHero from "../components/PageHero";
import AboutUs from "../components/AboutUs";

const About = () => {
  return (
    <>
      <PageHero name="About Us" />
      <AboutUs />
    </>
  );
};

export default About;
