import ImageOne from "../images/professional-businessman.jpg";

export const SliderData = [
  {
    title: "Experienced for Every Kind of IT Solution",
    price: "#25,000.00",
    path: "/report",
    label: "Submit Report",
    image: ImageOne,
    alt: "Individual Report",
  },
];
