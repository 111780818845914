import React from "react";
import styled from "styled-components";
// import { Button } from "./Button";
import { FaPhone } from "react-icons/fa";
import { AiOutlineMail, AiOutlineFieldTime } from "react-icons/ai";
import { GoLocation } from "react-icons/go";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 0rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 99%;
  }
`;

const Container = styled.div`
  width: 95%;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  display: flex;

  @media screen and (max-width: 480px) {
    margin: 1rem auto;
    width: 98%;
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const Section = styled.div`
  //   border: 1px solid #ccc;
  margin: 1rem;
  justify-content: center;
  align-items: baseline;
  color: #e64100;

  .icn {
    color: #e64100;
    font-size: 1.5rem;
  }

  span {
    color: #000;
    padding-left: 5px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0.3rem;
  }
`;

const Address = () => {
  return (
    <InfoDiv>
      <Container>
        <Section>
          <GoLocation className="icn" />
          <span>
            Suite 19, No. 23 Rwang Pam Street Plaza Jos Plateau State, Nigeria
          </span>
        </Section>
        <Section>
          <FaPhone className="icn" /> <span>08036071791, 09058914722</span>
        </Section>
        <Section>
          <AiOutlineMail className="icn" /> <span>info@scaninfo.com.ng</span>
        </Section>
        <Section>
          <AiOutlineFieldTime className="icn" /> <span>24 Hours Service</span>
        </Section>
      </Container>
      <hr />
    </InfoDiv>
  );
};

export default Address;
