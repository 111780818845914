import React, { useState } from "react";
import styled from "styled-components";

const InfoDiv = styled.section`
  width: 100%;
  padding: 0rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 50%;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;

  h1 {
    color: var(--first-color);
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  p {
    color: #276304;
    margin-bottom: 1rem;
  }

  span {
    width: 100%;
    display: inline-block;
  }

  input,
  select {
    width: 100%;
    padding: 0.5rem;
    margin: 1rem;
    border: 0;
    border-bottom: 2px solid #ccc;
    outline: none;
    background-color: hsla(45, 10%, 82%, 15%);
  }

  .err {
    border-bottom: 2px solid hsl(14, 92%, 76%);
  }

  .crt {
    border-bottom: 2px solid hsl(124, 62%, 76%);
  }

  .message {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid hsl(155, 50%, 75%);
    margin: 0.5rem;
    background-color: hsl(125, 90%, 75%);
    font-weight: 500;
  }

  .axios_error {
    display: flex;
    color: #fff;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid hsl(9, 98%, 51%);
    margin: 0.5rem;
    background-color: hsl(9, 82%, 55%);
    font-weight: 500;
  }

  input.sbm-btn {
    background-color: var(--dark-bg);
    color: #fff;
    white-space: nowrap;
    outline: none;
    border: none;
    border-radius: 8px;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-item: center;
    align: center;
    padding: 14px 35px;
    font-size: 20px;

    &:hover {
      transform: translateY(-2px);
      background-color: var(--button-color-alt);
    }
  }

  i {
    display: inline-block;
    color: hsl(0, 92%, 76%);
    margin-bottom: 4px;
  }

  textarea {
    width: 100%;
    padding: 0.5rem;
    margin: 1rem;
    outline: none;
    border: 0;
    border-bottom: 2px solid #ccc;
    background-color: hsla(45, 10%, 82%, 15%);
  }

  label {
    color: var(--text-color-dark);
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 1rem;

    span {
      color: #f00;
      font-weght: 700;
      display: inline;
    }
  }

  @media screen and (max-width: 480px) {
    width: 95%;

    span {
      width: 100%;
    }

    h1 {
      font-size: 1.5rem;
    }

    input,
    select,
    textarea {
      width: 90%;
    }
  }
`;

const ContactForm = () => {
  // const url = "/contact";

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    setFormErrors(validate(data));
    setIsSubmit(true);
  }

  function handleChange(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  // useEffect(() => {
  //   if (Object.keys(formErrors).length === 0 && isSubmit && data.name !== "") {
  //     publicRequest
  //       .post(url, data)
  //       .then((res) => {
  //         setData({
  //           name: "",
  //           email: "",
  //           phone: "",
  //           message: "",
  //         });
  //       })
  //       .catch((error) => {
  //         setFormErrors({ gen: "Something went wrong! please try again..." });
  //       });
  //   }
  // }, [formErrors, isSubmit, data]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phone) {
      errors.phone = "phone number is required!";
    } else if (values.phone.length < 10) {
      errors.phone = "phone number must be more than 10 characters";
    } else if (values.phone.length > 14) {
      errors.phone = "phone cannot exceed more than 14 characters";
    }
    if (!values.message) {
      errors.message = "Message is required!";
    }
    return errors;
  };

  return (
    <InfoDiv>
      <Container>
        <h1>How Can We Help You?</h1>
        <p>
          We are here to help and answer any question you might have. We look
          forward to hearing from you.
        </p>
        {Object.keys(formErrors).length === 0 && isSubmit ? (
          <div className="ui message success">
            Message sent successfully, thank you for contacting us, we'll get
            back to you soon!
          </div>
        ) : (
          ""
        )}
        {formErrors.gen && <i className="axios_error">{formErrors.gen}</i>}
        <form onSubmit={(e) => handleSubmit(e)}>
          <span>
            <label>
              Fullname <span className="noted">*</span>
            </label>
            <input
              type="text"
              className={formErrors.name ? "err" : isSubmit ? "crt" : undefined}
              name="name"
              id="fullname"
              placeholder="Fullname"
              onChange={(e) => handleChange(e)}
              value={data.name}
            />
            <i>{formErrors.name}</i>
          </span>

          <span>
            <label>
              Email Address <span className="noted">*</span>
            </label>
            <input
              type="text"
              name="email"
              className={
                formErrors.email ? "err" : isSubmit ? "crt" : undefined
              }
              id="email"
              placeholder="Email"
              onChange={(e) => handleChange(e)}
              value={data.email}
            />
            <i>{formErrors.email}</i>
          </span>

          <span>
            <label>
              Phone Number <span className="noted">*</span>
            </label>
            <input
              type="text"
              name="phone"
              className={
                formErrors.phone ? "err" : isSubmit ? "crt" : undefined
              }
              id="phone"
              placeholder="Phone Number"
              onChange={(e) => handleChange(e)}
              value={data.phone}
            />
            <i>{formErrors.phone}</i>
          </span>
          <span>
            <label>
              Message <span className="noted">*</span>
            </label>
            <textarea
              name="message"
              rows="5"
              className={
                formErrors.message ? "err" : isSubmit ? "crt" : undefined
              }
              id="messsage"
              placeholder="Message"
              onChange={(e) => handleChange(e)}
              value={data.message}
            ></textarea>
            <i>{formErrors.message}</i>
          </span>

          <input
            className="sbm-btn"
            value="Send Message"
            type="submit"
            primary="true"
          />
        </form>
      </Container>
    </InfoDiv>
  );
};

export default ContactForm;
