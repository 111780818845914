import React from "react";
import PageHero from "../components/PageHero";
import Projects from "../components/Projects";

const Project = () => {
  return (
    <>
      <PageHero name="Projects" />
      <Projects />
    </>
  );
};

export default Project;
