import React from "react";
import styled from "styled-components";
import about from "../images/about-us.webp";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 80%;
  padding: 2rem;
  margin: 1rem auto;
  background-color: #f8f8f8;

  img {
    width: 100%;
    height: auto;
    object-fit: fit;
  }

  h1 {
    color: #ba0404;
    font-weight: bold;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const AboutUs = () => {
  return (
    <InfoDiv>
      <img src={about} alt="" />
      <h1>Our Core Competences</h1>
      <p>
        The company has built and continue to maintain a strong ethos on the
        delivery of cost effective and quality projects for a variety of
        clients. We are thus able torespond to clients need in prompt fashion,
        in ways that are unique to their own demand. We leverage on our cosmic
        or vast knowledge, years of experience and skills through which we
        analyse and identify relevant and germane solutions applicable to each
        case; and then develop and apply strategies to meet specific client
        requirements and desires with excellent and exceptional results.
      </p>
      <p>
        Scan InfoTech sees opportunities in challenges and focuses on areas
        where there are tough problems to solve, a high rate of change, and
        where strategic value prevails
      </p>
      <h1>Who We Are</h1>
      <p>
        Scan Info Tech and IT Solutions is a vibrant and fast growing ﬁrm. It
        was incorporated to undertake development, provision, supply,
        deployment, implementation, e.t.c of Information and Communication
        Technologies (ICTS) solutions / services across all sectors
        (e-Services), and infrastructure such as surveillance, mapping, drones
        for all kinds of discipline etc. We have equally acquired necessary and
        suﬃcient skills in project management (both information Technology (IT)
        and none IT projects skills), proposal writing (both for grants seeking
        and research proposals), projects monitoring & evaluation, system
        auditing, forensic investigation, integrating ICT in agriculture, GIS
        and conduction of interviews for establishments. The ﬁrm is dully
        registered under the extant laws of the Federal Republic of Nigeria with
        Corporate Aﬀairs Commission (CAC) number Pl17080.{" "}
      </p>
      <h1>What We Do</h1>
      <p>
        Scan Infotect and IT Solutions is one of the skilled Information
        Technology (IT) companies in Nigeria with the sole aim "To become
        Nigeria's foremost global ICT Company, empowering our customers through
        technology". We offer solutions but not limited to the following:
      </p>
      <ul>
        <li>Telepresence and Teleconference Infrastructure Strategic</li>
        <li>
          Information Solutions Enterprise Network Infrastructure Enterprise
        </li>
        <li>
          Hardware and Storage Business Voice and Video Solutions including
        </li>
        <li>VoIP, and Wireless Surveillances Enterprise Security Solutions</li>
        <li>Penetration Testing and Forensic Consulting Enterprise Business</li>
        <li>Applications and Human Resource Solutions Portal and Website</li>
        <li>Development Enterprise Data Centre Infrastructure and Design</li>
        <li>
          Internet Services Provision and Systems Administration Trainings
        </li>
        <li>Broadband Internet Connectivity and Bandwidth Provision Data</li>
        <li>
          Centre Alternative Power Solutions and Backups Customised ICT/IT
        </li>
        <li>
          Training Solutions Both Physical and Remote ICT Consulting e-Waste
        </li>
        <li>Management Consultation ICT Project Monitoring and Evaluation</li>
        <li>
          General Systems Maintenance and Services SAN Backup and Disaster
        </li>
        <li>Recovery Solutions Setup Cisco Networking Academies in partner</li>
        <li>
          with Cisco Instructor Training Centres and Cisco Academy Support
        </li>
        <li>Centres Sales & Supply of all ICT Equipments/Softwares.</li>
      </ul>
      <h1>Our Strenght</h1>
      <p>
        As part of our commitment to provide our customers with an unparalleled
        quality of service, Scan InfoTech has a wide array of industry-leading
        certified experts to provide you with the depth and expertise required
        to guide your business through the complexities of emerging technology.
        On our team we have:{" "}
      </p>
      <ul>
        <li>Certified Ethical Hacker (CEH) </li>

        <li>Cisco Certified Academy Instructors (CCAI) </li>

        <li>Cisco Certified Voice Professionals (CCVP) </li>

        <li>Cisco Content Networking Specialists </li>

        <li>Cisco Certified Network Professionals (CCNP) </li>

        <li>Cisco Certified Design Professionals (CCDP) </li>

        <li>Cisco Certified Network Associates (CCNA) </li>

        <li>Cisco Certified Design Associates (CCDA) </li>

        <li>
          Cisco Certified Network Associate Cybersecurity Operations (CCNA
          CyberOps)
        </li>

        <li>Cisco Certified Network Associate Security (CCNA Security) </li>

        <li>Microsoft Certified Systems Engineers (MCSE) </li>

        <li>RedHat Certified Engineers (RHCE) </li>

        <li>Linux Professional Institute (LPI) Certificate </li>

        <li>SUN Solaris Certified Systems Administrators (SCSA) </li>

        <li>MikroTik Certified Network Associate (MTCNA) </li>

        <li>MikroTik Certified Routing Engineer (MTCRE) </li>

        <li>MikroTik Certified Wireless Engineers (MTCWE) </li>

        <li>MikroTik Certified Traffic Control Engineer (MTCTCE) </li>

        <li>MikroTik Certified User Management Engineers (MTCUME) </li>

        <li>MikroTik Certified Inter-networking Engineers (MTCINE) </li>

        <li>Certified VSAT Installers </li>
      </ul>
      <h1>OUR PARTNERS</h1>
      <ul>
        <li>Dapelson Integrated Global Solutions</li>
        <li>Vision Shield Multi-Concept Limited</li>
        <li>Managvis Information Technology</li>
        <li>Business Computing Solution</li>
      </ul>
    </InfoDiv>
  );
};

export default AboutUs;
