import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { menuData } from "../data/MenuData";
import { FaBars } from "react-icons/fa";
import logo from "../images/logo.png";

const Cont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Nav = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-around;
  padding: 1rem 2rem;
  width: 80%;
  z-index: 100;
  position: fixed;
  top: 45px;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out, top 0.3s ease-in-out,
    width 0.3s ease-in-out;

  &.scrolled {
    top: 0;
    width: 100%;
    background-color: #f8f8f8;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 0 0.5rem;
    justify-content: space-between;
  }
`;

const NavLink = css`
  color: #000;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: 960px) {
    padding: 0;
  }
`;

const Logo = styled(Link)`
  ${NavLink}
  font-size: 2rem;
  font-weight: 500;

  img {
    width: 80px;
    margin-right: 0.5rem;
  }

  @media screen and (max-width: 960px) {
    overflow: visible;
    font-size: 1.2rem;
  }
  @media screen and (max-width: 280px) and (max-height: 653px) {
    .hide-text {
      display: none;
    }
  }
`;

const MenuBars = styled(FaBars)`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    margin-right: 3px;
    height: 35px;
    width: 40px;
    cursor: pointer;
    color: black;
    position: absolute;
    top: 20px;
    right: 0;
    transform: translate(-50%, 25%);
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -40px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  ${NavLink}
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 14px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const Navbar = ({ toggle }) => {
  const [navBg, setNavBg] = useState(false);

  const changeBg = () => {
    if (window.scrollY >= 70) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBg);
    return () => {
      window.removeEventListener("scroll", changeBg);
    };
  }, []);

  return (
    <Cont>
      <Nav className={navBg ? "scrolled" : ""}>
        <Logo to="/">
          <img src={logo} alt="" />
          <span className="hide-text">Scan InfoTech And IT Solutions</span>
        </Logo>
        <MenuBars onClick={toggle} />
        <NavMenu>
          {menuData.map((item, index) => (
            <NavMenuLinks to={item.link} key={index}>
              {item.title}
            </NavMenuLinks>
          ))}
          <NavBtn></NavBtn>
        </NavMenu>
      </Nav>
    </Cont>
  );
};

export default Navbar;
