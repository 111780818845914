import React from "react";
import styled from "styled-components";
import roomImg from "../images/servers.webp";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 1rem auto;
  background-color: #f7f7fc;

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const Container = styled.div`
  padding: 1rem calc((100vw - 1100px) / 2);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  grid-template-rows: 600px;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 1rem 0;
    margin: 0 auto;
    gap: 1rem;
  }
`;

const ColumnLeft = styled.div`
  display: flex;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;

  h1 {
    color: #ba0404;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 0;
    width: 100%;
  }
`;

const WhatWeDo = () => {
  return (
    <InfoDiv>
      <Container>
        <ColumnLeft>
          <img src={roomImg} alt="Data Stock" />
        </ColumnLeft>
        <ColumnRight>
          <h1>What We Do</h1>
          <p>
            Scan Infotect and IT Solutions is one of the skilled Information
            Technology (IT) companies in Nigeria with the sole aim "To become
            Nigeria's foremost global ICT Company, empowering our customers
            through technology". We offer solutions but not limited to the
            following:
          </p>
          <ul>
            <li>Telepresence and Teleconference Infrastructure Strategic</li>
            <li>
              Information Solutions Enterprise Network Infrastructure Enterprise
            </li>
            <li>
              Hardware and Storage Business Voice and Video Solutions including
            </li>
            <li>
              VoIP, and Wireless Surveillances Enterprise Security Solutions
            </li>
            <li>
              Penetration Testing and Forensic Consulting Enterprise Business
            </li>
            <li>
              Applications and Human Resource Solutions Portal and Website
            </li>
            <li>
              Development Enterprise Data Centre Infrastructure and Design
            </li>
            <li>
              Internet Services Provision and Systems Administration Trainings
            </li>
            <li>
              Broadband Internet Connectivity and Bandwidth Provision Data
            </li>
            <li>
              Centre Alternative Power Solutions and Backups Customised ICT/IT
            </li>
            <li>
              Training Solutions Both Physical and Remote ICT Consulting e-Waste
            </li>
            <li>
              Management Consultation ICT Project Monitoring and Evaluation
            </li>
            <li>
              General Systems Maintenance and Services SAN Backup and Disaster
            </li>
            <li>
              Recovery Solutions Setup Cisco Networking Academies in partner
            </li>
            <li>
              with Cisco Instructor Training Centres and Cisco Academy Support
            </li>
            <li>Centres Sales & Supply of all ICT Equipments/Softwares.</li>
          </ul>
        </ColumnRight>
      </Container>
    </InfoDiv>
  );
};

export default WhatWeDo;
