import React from "react";
import PageHero from "../components/PageHero";
import Address from "../components/Address";
import ContactForm from "../components/ContactForm";

const Contact = () => {
  return (
    <div>
      <PageHero name="Contact Us" />
      <Address />
      <ContactForm />
    </div>
  );
};

export default Contact;
