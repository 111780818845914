import React from "react";
import styled from "styled-components";
import roomImg from "../images/services.svg";

const InfoDiv = styled.section`
  display: grid;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
    margin: 0 auto;
  }
`;

const CentralizedText = styled.div`
  text-align: center;
  margin: 0 auto;

  h1 {
    color: #ba0404;
    font-size: 3rem;
    font-weight: bold;
    width: 70%;
    margin: 0 auto 1rem;
  }

  p {
    margin: 0 auto 2rem;
  }

  @media screen and (max-width: 480px) {
    h1 {
      font-size: 1.3rem;
    }
  }
`;

const Container = styled.div`
  padding: 1rem calc((100vw - 1100px) / 2);
  margin: -100px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  grid-template-rows: 600px;

  h3 {
    color: var(--dark-bg);
    font-weight: bold;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 0;
    margin: 0 auto;
    gap: 0;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;

  h1 {
    color: var(--primary-color);
    margin-bottom: 1rem;
    font-size: clamp(1.5rem, 6vw, 2rem);
    text-align: center;
  }

  h3 {
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    height: 100%;

    p {
      margin-bottom: 0;
    }

    ul {
      margin: 0 auto 1rem;
    }

    li {
      margin: 1px 3px;
      line-height: 1rem;
    }
  }
`;

const ColumnMiddle = styled.div`
  display: flex;

  img {
    width: 100%;
    height: auto;
    object-fit: fit;
  }
`;

const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;

  h3 {
    color: #333;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 0;
    width: 100%;
  }
`;

const InfoSection = () => {
  return (
    <InfoDiv>
      <CentralizedText>
        <h1>
          More than <span style={{ color: "#000" }}>3 decades</span> of combined
          <span style={{ color: "#000" }}> IT Experience</span>
        </h1>
        <p>
          Nigeria's preferred provider of IT solutions, an exciting team and
          place to work
        </p>
      </CentralizedText>
      <Container>
        <ColumnLeft>
          <h3>Our Mission</h3>
          <p>
            We solve challenges, innovate solutions, and optimize performance as
            we take you beyond your dream.
          </p>
        </ColumnLeft>
        <ColumnMiddle>
          <img src={roomImg} alt="Data Stock" />
        </ColumnMiddle>
        <ColumnRight>
          <h3>Our Vision</h3>
          <p>
            Nigeria's preferred provider of IT solutions, an exciting team and
            place to work
          </p>
        </ColumnRight>
      </Container>
    </InfoDiv>
  );
};

export default InfoSection;
